<template>
    <form id="contactForm" @submit.prevent="submit">
        <div class="form-group">
            <!-- Name Field -->
            <input type="text" id="name" placeholder="Nome*" v-model.trim="$v.name.$model">
            <div class="help-block" v-if="name && !$v.name.required">Campo obbligatorio</div>
            <div class="help-block" v-if="name && !$v.name.minLength">Il nome deve essere lungo almeno
                {{$v.name.$params.minLength.min}} caratteri.
            </div>
        </div>
        <div class="form-group">
            <!-- Email Field -->
            <input type="email" id="email" placeholder="Email*" v-model.trim="$v.email.$model">
            <div class="help-block" v-if="email && !$v.email.required">Campo obbligatorio</div>
            <div class="help-block" v-if="email && !$v.email.email">Email non valida</div>
        </div>
        <div class="form-group">
            <!-- Message Field -->
            <textarea id="message" name="message" placeholder="Messaggio*" v-model.trim="$v.message.$model"></textarea>
            <div class="help-block" v-if="message && !$v.message.required">Campo obbligatorio</div>
            <div class="help-block" v-if="message && !$v.message.minLength">Il messaggio deve essere lungo almeno
                {{$v.message.$params.minLength.min}} caratteri.
            </div>

            <p class="subtle">* campo obbligatorio</p>

            <div class="form-group">
                <!-- GDPR -->
                <label class="d-flex">
                    <input type="checkbox" id="gdpr" class="m-0" style="width:auto;" v-model="gdpr">
                    <a href="https://www.freeprivacypolicy.com/privacy/view/c6888fc6f856ed6a9d4d63261d781dc6"
                       class="m-auto" target="_blank">
                        * Ho letto e accetto i termini di utilizzo e la policy sulla privacy</a>
                </label>
            </div>

            <!-- Submit Button -->
            <button type="submit" class="button"
                    :disabled="$v.name.$invalid || $v.email.$invalid || $v.message.$invalid || !gdpr">INVIA
            </button>
            <!-- Success Message -->
            <div class="text-center" v-if="submitStatus === 'OK'">Messaggio inviato con successo</div>
            <div class="text-center" v-if="submitStatus === 'ERROR'">Impossibile inviare il messaggio</div>
            <div class="text-center" v-if="submitStatus === 'PENDING'">Invio in corso...</div>
        </div>
    </form>
</template>

<script>
    import {email, minLength, required} from 'vuelidate/lib/validators'

    export default {
        name: "ContactForm",
        data() {
            return {
                name: null,
                email: null,
                message: null,
                submitStatus: null,
                gdpr: null,
            }
        },
        methods: {
            submit() {
                console.log('submit!');
                this.$v.$touch();
                if (this.$v.$invalid) {
                    this.submitStatus = 'ERROR'
                } else {
                    this.submitStatus = 'PENDING';

                    axios.post(this.route('contact'), {
                        name: this.name,
                        email: this.email,
                        message: this.message
                    }).then(function () {
                        this.submitStatus = 'OK'
                    }).catch(function () {
                        this.submitStatus = 'ERROR'
                    });
                }
            }
        },
        validations: {
            name: {
                required,
                minLength: minLength(4)
            },
            email: {
                required,
                email
            },
            message: {
                required,
                minLength: minLength(10)
            }
        }
    }
</script>

<style scoped>

</style>