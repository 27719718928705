<template>
    <!-- /// TEAM SECTION /// -->
    <div id="pub" class="large-margin">
        <a href="pub"></a><!-- Nav Anchor -->
        <div class="row heading tiny-margin">
            <div class="col-md-auto">
                <h1 class="animation-element slide-down">IL <span class="colored">PUB</span></h1>
            </div>
            <div class="col">
                <hr class="animation-element extend">
            </div>
        </div>

        <div class="row small-margin">
            <div class="col-md-11">
                <p>Alcuni dei ritagli del nostro fantastico pub, innamorati della location gustandoti queste fantastiche pic</p>
            </div>
        </div>
        <div class="grid-gallery small-margin">
            <div class="row">
                <div class="col-md-4 gallery-item">
                    <a href="images/pub/pub-1.jpg" data-lightbox="studio_gallery">
                    <div class="overlay gallery">
                        <i class="fa fa-picture-o fa-3x"></i>
                    </div>
                    <img src="images/pub/pub-1.jpg" data-src="images/pub/pub-1.jpg" class="img-fluid b-lazy" alt="">
                    </a>
                </div>
                <div class="col-md-4 gallery-item">
                    <a href="images/pub/pub-2.jpg" data-lightbox="studio_gallery">
                    <div class="overlay gallery">
                        <i class="fa fa-picture-o fa-3x"></i>
                    </div>
                    <img src="images/pub/pub-2.jpg" data-src="images/pub/pub-2.jpg" class="img-fluid b-lazy" alt="">
                    </a>
                </div>
                <div class="col-md-4 gallery-item">
                    <a href="images/pub/pub-3.jpg" data-lightbox="studio_gallery">
                    <div class="overlay gallery">
                        <i class="fa fa-picture-o fa-3x"></i>
                    </div>
                    <img src="images/pub/pub-3.jpg" data-src="images/pub/pub-3.jpg" class="img-fluid b-lazy" alt="">
                    </a>
                </div>
            </div>
            <div class="row">
                <div class="col-md-4 gallery-item">
                    <a href="images/pub/pub-4.jpg" data-lightbox="studio_gallery">
                    <div class="overlay gallery">
                        <i class="fa fa-picture-o fa-3x"></i>
                    </div>
                    <img src="images/pub/pub-4.jpg" data-src="images/pub/pub-4.jpg" class="img-fluid b-lazy" alt="">
                    </a>
                </div>
                <div class="col-md-4 gallery-item">
                    <a href="images/pub/pub-5.jpg" data-lightbox="studio_gallery">
                    <div class="overlay gallery">
                        <i class="fa fa-picture-o fa-3x"></i>
                    </div>
                    <img src="images/pub/pub-5.jpg" data-src="images/pub/pub-5.jpg" class="img-fluid b-lazy" alt="">
                    </a>
                </div>
                <div class="col-md-4 gallery-item">
                    <a href="images/pub/pub-7.jpg" data-lightbox="studio_gallery">
                    <div class="overlay gallery">
                        <i class="fa fa-picture-o fa-3x"></i>
                    </div>
                    <img src="images/pub/pub-7.jpg" data-src="images/pub/pub-7.jpg" class="img-fluid b-lazy" alt="">
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Pub",
    }
</script>

<style scoped>

</style>
