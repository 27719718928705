<template>
    <div>
        <to-header/>

        <hero/>

        <div class="container">
            <about/>
            <pub/>
            <our-menu/>
            <services/>
            <games/>
            <contact/>
        </div>

        <to-footer/>
    </div>
</template>

<script>
    import ToHeader from "./commons/Header";
    import OurMenu from "./home/Menu";
    import Hero from "./home/Hero";
    import About from "./home/About";
    import Games from "./home/Games";
    import Services from "./home/Services";
    import Pub from "./home/Pub";
    import ToFooter from "./commons/Footer";
    import Contact from "./home/Contact";

    export default {
        name: "HomeView",
        components: {Contact, OurMenu, ToFooter, Pub, Services, Games, About, Hero, ToHeader}
    }
</script>

<style scoped>

</style>
