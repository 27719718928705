<template>
    <!-- /// GAMES SECTION /// -->
    <div id="games" class="large-margin">
        <a href="games"></a><!-- Nav Anchor -->
        <div class="row heading tiny-margin">
            <div class="col-md-auto">
                <h1 class="animation-element slide-down">I NOSTRI <span class="colored">GIOCHI</span></h1>
            </div>
            <div class="col">
                <hr class="animation-element extend">
            </div>
        </div>
        <div class="row ">
            <div class="col-md-11 small-margin">
                <p>Ecco la collana di giochi attualmente presente nella nostra bacheca</p>
            </div>
        </div>
        <div class="games-portfolio ">

            <!-- Game Card Thumbnail -->
            <div v-for="game in games" :key="game.id" class="row game-card pc new">
                <div class="col-lg-12 col-xl-5 game-card-left">
                    <img :src="game.picture" :data-src="game.picture" class="img-fluid b-lazy" :alt="game.title + ' thumbnail'">
                </div>
                <div class="col-lg-12 col-xl-7 game-card-right">
                    <h2 class="short-hr-left">{{ game.title }}</h2>
                    <p class="tags"><span class="subtle">{{ game.category }}</span></p>
                    <p class="game-description">{{ game.description }}</p>

                    <div class="row">
                        <div v-if="game.ps4" class="col-md-6 steam-btn">
                            <font-awesome-icon :icon="['fab', 'playstation']" size="3x" style="color: #003791"/>
                            <p>AVAILABLE FOR <br><span class="spaced">PS4</span></p>
                        </div>

                        <div v-if="game.xboxone" class="col-md-6 steam-btn">
                            <font-awesome-icon :icon="['fab', 'xbox']" size="3x" style="color: #107c10"/>
                            <p>AVAILABLE FOR <br><span class="spaced">Xbox One</span></p>
                        </div>
                    </div>
                </div>
            </div><!-- Game Card End -->
        </div>
    </div>
</template>

<script>
    import FontAwesomeIcon from '@fortawesome/vue-fontawesome'

    export default {
        name: "Games",
        components: {
            FontAwesomeIcon,
        },
        mounted() {
            let self = this;
            axios.get(this.route('api.v1.games.all')).then(function (response) {
                self.games = response.data;
            });
        },
        data() {
            return {
                games: []
            }
        }
    }
</script>

<style scoped>

</style>
