<template>
    <fixed-header :fixed.sync="fixed" :threshold="height">
        <header id="main-header">
            <nav class="navbar fixed-top navbar-expand-lg navbar-dark" v-bind:class="{scrolled: fixed}">
                <div class="container">
                    <!-- Site Logo -->
                    <a id="logo" class="navbar-brand" href="#">
                        <img class="img-fluid" src="images/logo.png" alt="site logo"></a>
                    <!-- Dropdown Button -->
                    <button id="hamburger" class="navbar-toggler" type="button"
                            v-bind:class="hamburger ? 'open' : 'collapsed'"
                            v-on:click="hamburger = !hamburger" aria-label="Toggle navigation">
                        <span class="icon-bar"></span>
                        <span class="icon-bar"></span>
                        <span class="icon-bar"></span>
                    </button>
                    <!-- Navigation Links -->
                    <scrollactive class="collapse navbar-collapse" active-class="active" :offset="offset"
                                  v-bind:class="{show: hamburger}">
                        <ul class="navbar-nav ml-auto">
                            <li class="nav-item active" v-for="item in menu" :key="item.title">
                                <a class="scrollactive-item nav-link" :href="item.href">{{ item.title }}</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" href="https://wa.me/+390808764981/?text=Ciao%21%20Vorrei%20ordinare"
                                   target="_blank" rel="noopener noreferrer">Ordina online</a>
                            </li>
                        </ul>
                    </scrollactive>
                </div>
            </nav>
        </header>
    </fixed-header>
</template>

<script>
import FixedHeader from 'vue-fixed-header'

export default {
    name: "ToHeader",
    components: {
        FixedHeader,
    },
    data() {
        return {
            hamburger: false,
            height: parseInt($(window).height()),
            offset: 150,
            fixed: false,

            menu: [
                {
                    title: 'Chi Siamo',
                    href: '#about',
                },
                {
                    title: 'Il Pub',
                    href: '#pub',
                },
                {
                    title: 'Menu',
                    href: '#menu',
                },
                {
                    title: 'Servizi Offerti',
                    href: '#services',
                },
                {
                    title: 'I Nostri Giochi',
                    href: '#games',
                },
                {
                    title: 'Contattaci',
                    href: '#contact',
                }
            ]
        }
    }
}
</script>

<style scoped>

</style>
