<template>
    <!-- /// GAMES SECTION /// -->
    <div id="services" class="large-margin">
        <a href="services"></a><!-- Nav Anchor -->
        <div class="row heading tiny-margin">
            <div class="col-md-auto">
                <h1 class="animation-element slide-down">SERVIZI <span class="colored">OFFERTI</span></h1>
            </div>
            <div class="col">
                <hr class="animation-element extend">
            </div>
        </div>

        <div class="row medium-margin">
            <div class="col-md-11 tiny-margin">
                <p>Nel nostro Gaming Pub potrai trovare diverse tipologie di intrattenimento, dalle postazioni gaming
                    corredate da zona ristoro, all'area sport, passando per la postazione VR</p>
            </div>
            <div id="full-row" class="row text-center">

                <!-- Pub -->
                <div class="col-md-4 mb-4">
                    <div class="team-card p-0 position-relative">
                        <a href="images/services/pub-1.jpg" data-lightbox="pub-lightbox">
                            <div class="overlay">
                                <font-awesome-icon icon="image" size="3x"/>
                            </div>
                            <img src="images/services/pub-1.jpg" data-src="images/services/pub-1.jpg" class="img-fluid b-lazy"
                                 alt="Pub"></a>

                        <a v-for="image in pub" :href="image" data-lightbox="pub-lightbox"></a>
                    </div>

                    <p class="team-name pt-2 pb-2">Pub</p>
                </div>
                <!-- End Pub -->

                <!-- American Bar -->
                <div class="col-md-4 mb-4">
                    <div class="team-card p-0 position-relative">
                        <a href="images/services/american-bar-1.jpg" data-lightbox="american-bar-lightbox">
                            <div class="overlay">
                                <font-awesome-icon icon="image" size="3x"/>
                            </div>
                            <img src="images/services/american-bar-1.jpg" data-src="images/services/american-bar-1.jpg" class="img-fluid b-lazy"
                                 alt="American Bar"></a>

                        <a v-for="image in americanbar" :href="image" data-lightbox="american-bar-lightbox"></a>
                    </div>

                    <p class="team-name pt-2 pb-2">American Bar</p>
                </div>
                <!-- End American Bar -->

                <!-- Caffetteria -->
                <div class="col-md-4 mb-4">
                    <div class="team-card p-0 position-relative">
                        <a href="images/services/caffetteria-1.jpg" data-lightbox="caffetteria-lightbox">
                            <div class="overlay">
                                <font-awesome-icon icon="image" size="3x"/>
                            </div>

                            <img src="images/services/caffetteria-1.jpg" data-src="images/services/caffetteria-1.jpg" class="img-fluid b-lazy"
                                 alt="Caffetteria"></a>

                        <a v-for="image in caffetteria" :href="image" data-lightbox="caffetteria-lightbox"></a>
                    </div>

                    <p class="team-name pt-2 pb-2">Caffetteria</p>
                </div>
                <!-- End Caffetteria -->

                <!-- Eventi -->
                <div class="col-md-4 mb-4">
                    <div class="team-card p-0 position-relative">
                        <a href="images/services/eventi-1.jpg" data-lightbox="eventi-lightbox">
                            <div class="overlay">
                                <font-awesome-icon icon="image" size="3x"/>
                            </div>
                            <img src="images/services/eventi-1.jpg" data-src="images/services/eventi-1.jpg" class="img-fluid b-lazy"
                                 alt="Eventi"></a>

                        <a v-for="image in eventi" :href="image" data-lightbox="eventi-lightbox"></a>
                    </div>

                    <p class="team-name pt-2 pb-2">Eventi</p>
                </div>
                <!-- End Eventi -->

                <!-- Sport -->
                <div class="col-md-4 mb-4">
                    <div class="team-card p-0 position-relative">
                        <a href="images/services/sport-streaming-1.jpg" data-lightbox="sport-lightbox">
                            <div class="overlay">
                                <font-awesome-icon icon="image" size="3x"/>
                            </div>
                            <img src="images/services/sport-streaming-1.jpg" data-src="images/services/sport-streaming-1.jpg" class="img-fluid b-lazy"
                                 alt="Sport Streaming"></a>

                        <a v-for="image in sport" :href="image" data-lightbox="sport-lightbox"></a>
                    </div>

                    <p class="team-name pt-2 pb-2">Sport Streaming</p>
                </div>
                <!-- End Sport -->

                <!-- Videogames -->
                <div class="col-md-4 mb-4">
                    <div class="team-card p-0 position-relative">
                        <a href="images/services/videogames-1.jpg" data-lightbox="videogames-lightbox">
                            <div class="overlay">
                                <font-awesome-icon icon="image" size="3x"/>
                            </div>
                            <img src="images/services/videogames-1.jpg" data-src="images/services/videogames-1.jpg" class="img-fluid b-lazy"
                                 alt="Videogames"></a>

                        <a v-for="image in videogames" :href="image" data-lightbox="videogames-lightbox"></a>
                    </div>

                    <p class="team-name pt-2 pb-2">Videogames</p>
                </div>
                <!-- End Videogames -->

            </div>
        </div>
    </div>
</template>

<script>
    import FontAwesomeIcon from '@fortawesome/vue-fontawesome'

    export default {
        name: "Services",
        components: {
            FontAwesomeIcon
        },
        data() {
            return {
                pub: [
                    'images/services/pub-2.jpg',
                    'images/services/pub-3.jpg',
                    'images/services/pub-4.jpg',
                    'images/services/pub-5.jpg',
                    'images/services/pub-6.jpg',
                ],
                americanbar: [
                    'images/services/american-bar-2.jpg',
                    'images/services/american-bar-3.jpg',
                    'images/services/american-bar-4.jpg',
                    'images/services/american-bar-5.jpg',
                    'images/services/american-bar-6.jpg'
                ],
                caffetteria: [
                    'images/services/caffetteria-2.jpg',
                    'images/services/caffetteria-3.jpg',
                    'images/services/caffetteria-4.jpg',
                ],
                eventi: [
                    'images/services/eventi-2.jpg',
                    'images/services/eventi-3.jpg',
                ],
                sport: [
                    'images/services/sport-streaming-2.jpg',
                    'images/services/sport-streaming-3.jpg',
                ],
                videogames: [
                    'images/services/videogames-2.jpg',
                    'images/services/videogames-3.jpg',
                    'images/services/videogames-4.jpg',
                    'images/services/videogames-5.jpg',
                    'images/services/videogames-6.jpg',
                ],
            }
        }
    }
</script>

<style scoped>

</style>