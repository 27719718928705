<template>
    <!-- /// ABOUT SECTION /// -->
    <div id="about" class="large-margin">
        <a href="about"></a><!-- Nav Anchor -->
        <div class="row heading tiny-margin">
            <div class="col-md-auto">
                <h1 class="animation-element slide-down">CHI <span class="colored">SIAMO</span></h1>
            </div>
            <div class="col">
                <hr class="animation-element extend">
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <p class="small-margin">
                    <b>Turnover Gaming Pub</b> è il nuovo e rivoluzionario gaming pub di Bari che mette a disposizione,
                    in un ambiente accogliente e confortevole, postazioni accessoriate di <b>consolle PlayStation
                    e XBox</b> per il tuo intrattenimento e quello dei tuoi amici. Da Turnover Gaming Pub,
                    avrai a disposizione fino a dieci postazioni indipendenti, destinate all’utilizzo delle consolle
                    di gioco in pieno confort, attrezzate con divano, tavolino ed una TV collegata alla consolle scelta.
                    Una di queste, dedicata alla <b>realtà aumentata (VR)</b>, ti permetterà anche di vivere una esperienza
                    super immersiva ed in prima persona.
                </p>

                <p class="small-margin">
                    Ogni postazione di gioco dotata di un tablet (touch screen), inoltre, ti consentirà di abbinare il
                    video intrattenimento del gaming alla possibilità di effettuare ordini <b>food&drink</b> al tavolo tramite
                    il menù virtuale o di chiedere supporto al nostro personale per qualsiasi esigenza.
                    Da Turnover Gaming Pub, infatti, potrai intrattenerti anche, consumando sia al banco che ai
                    tavoli ottimi aperitivi, stuzzichini, panini, drink e molto altro.
                </p>

                <p class="small-margin">
                    Periodicamente, infine, un ampio calendario di <b>eventi</b> come la trasmissione di partite sportive in
                    diretta (campionati italiani, competizioni europee di calcio, campionati mondiali ed eventi
                    sportivi di interesse generale), tornei di videogiochi ed eventi dedicati alla pubblicazione
                    di nuovi videogame, Happy-Hour ed eventi con artisti e dj.
                </p>
            </div>
            <div class="col-md-6">
                <img src="images/about.jpg" data-src="images/about.jpg" class="img-fluid b-lazy" alt="il locale">

                <img src="images/about-2.jpg" data-src="images/about-2.jpg" class="img-fluid b-lazy mt-3" alt="il locale">
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "About"
    }
</script>

<style scoped>

</style>