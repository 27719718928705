<template>
    <router-view></router-view>
</template>

<script>
    import FontAwesomeIcon from '@fortawesome/vue-fontawesome';

    export default {
        name: "App",
        components: {
            FontAwesomeIcon
        },
        mounted() {

        },
        created() {
        },
        data() {
            return {}
        },
        methods: {}
    }
</script>

<style lang="scss" scoped>

</style>