<template>
    <!-- /// CONTACT SECTION /// -->
    <div id="contact" class="large-margin">
        <a href="contact"></a><!-- Nav Anchor -->
        <div class="row heading tiny-margin">
            <div class="col-md-auto">
                <h1 class="animation-element slide-down">RESTA IN <span class="colored">CONTATTO</span></h1>
            </div>
            <div class="col">
                <hr class="animation-element extend">
            </div>
        </div>
        <div class="">
            <div class="row small-margin">
                <div class="col-md-11">
                    <p>Vuoi prenotare un evento, richiedere informazioni per organizzare una festa o un dj set, o semplicemente farci sapere che ne pensi del nostro pub?</p>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <h2 class="short-hr-left">SCRIVICI</h2>
                    <contact-form></contact-form>
                </div>
                <div class="col-md-6">
                    <h2 class="short-hr-left">DETTAGLI</h2>
                    <div id="contact-info">
                        <ul>
                            <li><li><i><font-awesome-icon icon="phone"/></i>
                            <p>Telefono: <span class="colored"><a href="tel:+39 080 876 4981">+39 080 876 4981</a></span></p>
                        </li>
                            <li><li><i><font-awesome-icon icon="envelope"/></i>
                            <p>Email: <span class="colored"><a href="mailto:info@turnover.pub">info@turnover.pub</a></span></p>
                        </li>
                            <li><i><font-awesome-icon icon="globe"/></i>
                                <p>Sito: <span class="colored"><a href="https://www.turnover.pub" target="_blank">www.turnover.pub</a></span></p>
                            </li>

                            <li><i><font-awesome-icon icon="map-marker-alt"/></i>
                                <p>Address: <span class="colored">Via Guido de Ruggiero 38 - 70125 Bari</span></p>
                            </li>

                        </ul>
                    </div>
                    <!-- Google Map -->
                    <div id="map-canvas"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import FontAwesomeIcon from '@fortawesome/vue-fontawesome'
    import ContactForm from "../ContactForm";

    export default {
        name: "Contact",
        components: {
            ContactForm,
            FontAwesomeIcon
        }
    }
</script>

<style scoped>

</style>
