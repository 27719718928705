<template>
    <!-- /// HERO SECTION /// -->
    <div id="hero-section" class="medium-margin">
        <div class="row hero-unit">
            <div class="hero-overlay"></div>
            <video autoplay loop muted poster="images/poster.jpg" id="bgvid" class="img-fluid">
                <source src="images/bg-vid.mp4" type="video/mp4"><!-- your video goes here -->
            </video>
            <div class="hero-caption">
                <h1>Il mondo dei videogiochi <span class="colored">come non lo avete mai gustato</span></h1>

                <div class="d-flex">
                    <a class="button"
                       href="https://wa.me/+390808764981/?text=Ciao%21%20Vorrei%20ordinare"
                       target="_blank" rel="noopener noreferrer">Ordina online</a>
                </div>
            </div>
        </div>
    </div>
    <!-- Hero Section End -->
</template>

<script>
export default {
    name: "Hero"
}
</script>

<style scoped>

</style>
