<template>
    <!-- /// MENU SECTION /// -->
    <div id="menu" class="large-margin">
        <a href="menu"></a><!-- Nav Anchor -->
        <div class="row heading tiny-margin">
            <div class="col-md-auto">
                <h1 class="animation-element slide-down">IL <span class="colored">MENU</span></h1>
            </div>
            <div class="col">
                <hr class="animation-element extend">
            </div>
        </div>

        <div class="row medium-margin">
            <div class="col-md-11 tiny-margin">
                <p>Hai già fame? Consulta il nostro menu in anteprima e non trovarti impreparato davanti alle consolle.</p>
            </div>
            <div id="full-row" class="row text-center">

                <!-- Menu -->
                <div class="col-md-4 mb-4" v-for="category in categories" :key="category.id">
                    <div class="team-card p-0 position-relative">
                        <a @click="showModal(category)">
                            <div class="overlay category">
                            </div>
                            <img :src="category.picture" :data-src="category.picture" class="img-fluid b-lazy"
                                 :alt="category.name + ' thumbnail'"></a>

                    </div>

                    <p class="team-name pt-2 pb-2">{{ category.name }}</p>
                </div>
                <!-- End Pub -->

            </div>
        </div>

        <b-modal id="menu-modal" ref="menu-modal" size="xl" :title="category != null ? category.name : ''" hide-footer>
            <div v-if="category" class="games-portfolio ">
                <!-- Game Card Thumbnail -->
                <div v-for="product in category.products" :key="product.id" class="row game-card">
                    <div class="col-lg-12 col-xl-5 col-md-6 game-card-left">
                        <img :src="product.picture" :data-src="product.picture" class="img-fluid b-lazy" :alt="product.title + ' thumbnail'">
                    </div>
                    <div class="col-lg-12 col-xl-7 game-card-right">
                        <h2 class="short-hr-left">{{ product.name }}</h2>
                        <p class="tags"><span class="subtle">{{ product.ingredients }}</span></p>
                        <p class="game-description">€ {{ product.price }}</p>
                    </div>
                </div><!-- Game Card End -->
            </div>
        </b-modal>
    </div>
</template>

<script>
    import FontAwesomeIcon from '@fortawesome/vue-fontawesome'

    export default {
        name: "Menu",
        components: {
            FontAwesomeIcon
        },
        mounted() {
            let self = this;
            axios.get(this.route('api.v1.categories.all')).then(function (response) {
                self.categories = response.data;
            });

            this.$root.$on('bv::modal::hidden', () => {
                self.category = null
                document.documentElement.classList.remove("modal-open")
            })
        },
        data() {
            return {
                categories: [],
                category: null,
            }
        },
        methods: {
            showModal(category) {
                this.category = category
                document.documentElement.classList.add("modal-open")
                this.$refs['menu-modal'].show()
            }
        }
    }
</script>

<style scoped>
    .overlay.category {
        cursor: pointer;
    }
</style>
