<template>
    <div>
        <div class="container pt-4">
            <our-menu/>
        </div>

        <to-footer/>
    </div>
</template>

<script>
    import OurMenu from "./home/Menu";
    import ToFooter from "./commons/Footer";

    export default {
        name: "MenuView",
        components: {OurMenu, ToFooter}
    }
</script>

<style scoped>

</style>
