<template>
    <!-- /// FOOTER /// -->
    <footer id="main-footer">
        <div id="footer">
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <p id="copyright">&copy; Turnover Gaming Pub
                            <span id="year">@{{ copyrightYear }}</span> - Developed by <a
                                    href="https://www.angelocassano.it" target="_blank">
                                Angelo Cassano</a></p>
                        <ul class="social-links"> <!-- Social Media Icons -->
                            <li><a href="https://www.facebook.com/turnovergamingpub/" target="_blank">
                                <font-awesome-icon :icon="['fab', 'facebook-f']"/>
                            </a>
                            </li>
                            <li><a href="https://www.instagram.com/turnovergamingpub/" target="_blank">
                                <font-awesome-icon :icon="['fab', 'instagram']"/>
                            </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div><!-- Container End -->
        </div>
    </footer>
    <!-- Footer End -->
</template>

<script>
    import FontAwesomeIcon from '@fortawesome/vue-fontawesome'

    export default {
        name: "ToFooter",
        components: {
            FontAwesomeIcon
        },
        data() {
            return {
                copyrightYear: new Date().getFullYear()
            }
        }
    }
</script>

<style scoped>

</style>