var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"large-margin",attrs:{"id":"contact"}},[_c('a',{attrs:{"href":"contact"}}),_vm._v(" "),_vm._m(0),_vm._v(" "),_c('div',{},[_vm._m(1),_vm._v(" "),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('h2',{staticClass:"short-hr-left"},[_vm._v("SCRIVICI")]),_vm._v(" "),_c('contact-form')],1),_vm._v(" "),_c('div',{staticClass:"col-md-6"},[_c('h2',{staticClass:"short-hr-left"},[_vm._v("DETTAGLI")]),_vm._v(" "),_c('div',{attrs:{"id":"contact-info"}},[_c('ul',[_c('li'),_c('li',[_c('i',[_c('font-awesome-icon',{attrs:{"icon":"phone"}})],1),_vm._v(" "),_vm._m(2)]),_vm._v(" "),_c('li'),_c('li',[_c('i',[_c('font-awesome-icon',{attrs:{"icon":"envelope"}})],1),_vm._v(" "),_vm._m(3)]),_vm._v(" "),_c('li',[_c('i',[_c('font-awesome-icon',{attrs:{"icon":"globe"}})],1),_vm._v(" "),_vm._m(4)]),_vm._v(" "),_c('li',[_c('i',[_c('font-awesome-icon',{attrs:{"icon":"map-marker-alt"}})],1),_vm._v(" "),_vm._m(5)])])]),_vm._v(" "),_c('div',{attrs:{"id":"map-canvas"}})])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row heading tiny-margin"},[_c('div',{staticClass:"col-md-auto"},[_c('h1',{staticClass:"animation-element slide-down"},[_vm._v("RESTA IN "),_c('span',{staticClass:"colored"},[_vm._v("CONTATTO")])])]),_vm._v(" "),_c('div',{staticClass:"col"},[_c('hr',{staticClass:"animation-element extend"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row small-margin"},[_c('div',{staticClass:"col-md-11"},[_c('p',[_vm._v("Vuoi prenotare un evento, richiedere informazioni per organizzare una festa o un dj set, o semplicemente farci sapere che ne pensi del nostro pub?")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v("Telefono: "),_c('span',{staticClass:"colored"},[_c('a',{attrs:{"href":"tel:+39 080 876 4981"}},[_vm._v("+39 080 876 4981")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v("Email: "),_c('span',{staticClass:"colored"},[_c('a',{attrs:{"href":"mailto:info@turnover.pub"}},[_vm._v("info@turnover.pub")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v("Sito: "),_c('span',{staticClass:"colored"},[_c('a',{attrs:{"href":"https://www.turnover.pub","target":"_blank"}},[_vm._v("www.turnover.pub")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v("Address: "),_c('span',{staticClass:"colored"},[_vm._v("Via Guido de Ruggiero 38 - 70125 Bari")])])
}]

export { render, staticRenderFns }