import { render, staticRenderFns } from "./Hero.vue?vue&type=template&id=4e0c8b0f&scoped=true"
import script from "./Hero.vue?vue&type=script&lang=js"
export * from "./Hero.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4e0c8b0f",
  null
  
)

export default component.exports