$(document).ready(function () {
  "use strict";

  /*
  // LAZY LOADING IMAGES
  var bLazy = new Blazy();

  */
  // ANIMATIONS
  var $animation_elements = $('.animation-element');
  var $window = $(window);

  function check_if_in_view() {
    var window_height = $window.height();
    var window_top_position = $window.scrollTop();
    var window_bottom_position = (window_top_position + window_height);

    $.each($animation_elements, function () {
      var $element = $(this);
      var element_height = $element.outerHeight();
      var element_top_position = $element.offset().top + 150;
      var element_bottom_position = (element_top_position + element_height);

      //check to see if this current container is within viewport
      if ((element_bottom_position >= window_top_position) &&
          (element_top_position <= window_bottom_position)) {
        $element.addClass('in-view');
      }
    });
  }

  $window.on('scroll resize', check_if_in_view);
  $window.trigger('scroll');

  //STOP VIDEO FROM PLAYING AFTER CLOSING A MODAL
  $("body").on('hidden.bs.modal', function (e) {
    var $iframes = $(e.target).find("iframe");
    $iframes.each(function (index, iframe) {
      $(iframe).attr("src", $(iframe).attr("src"));
    });
  });

  // LIGHTBOX OPTIONS
  /*
  lightbox.option({
    'resizeDuration': 500,
    'imageFadeDuration': 500,
    'wrapAround': true
  });
  */

  // VIDEO LIGHTBOX
  //$(".js-video-button").modalVideo();

  // LOAD GOOGLE MAP
  google.maps.event.addDomListener(window, 'load', init);

  function init() {
    // Basic options for a simple Google Map
    // For more options see: https://developers.google.com/maps/documentation/javascript/reference#MapOptions
    var mapOptions = {
      // How zoomed in you want the map to start at (always required)
      zoom: 15,

      // The latitude and longitude to center the map (always required)
      center: new google.maps.LatLng(41.110809, 16.874760), // New York

      // How you would like to style the map.
      // This is where you would paste any style found on Snazzy Maps.
      styles: [{
        "featureType": "all",
        "elementType": "labels.text.fill",
        "stylers": [{"saturation": 36}, {"color": "#000000"}, {"lightness": 40}]
      }, {
        "featureType": "all",
        "elementType": "labels.text.stroke",
        "stylers": [{"visibility": "on"}, {"color": "#000000"}, {"lightness": 16}]
      }, {
        "featureType": "all",
        "elementType": "labels.icon",
        "stylers": [{"visibility": "off"}]
      }, {
        "featureType": "administrative",
        "elementType": "geometry.fill",
        "stylers": [{"lightness": 20}, {"color": "#000000"}]
      }, {
        "featureType": "administrative",
        "elementType": "geometry.stroke",
        "stylers": [{"color": "#000000"}, {"lightness": 17}, {"weight": 1.2}]
      }, {
        "featureType": "administrative.country",
        "elementType": "labels.text.fill",
        "stylers": [{"visibility": "on"}, {"color": "#ffffff"}]
      }, {
        "featureType": "administrative.province",
        "elementType": "geometry.fill",
        "stylers": [{"visibility": "simplified"}]
      }, {
        "featureType": "administrative.province",
        "elementType": "labels.text.fill",
        "stylers": [{"color": "#ffffff"}]
      }, {
        "featureType": "administrative.province",
        "elementType": "labels.text.stroke",
        "stylers": [{"weight": "0.01"}, {"invert_lightness": true}, {"color": "#5eab0f"}]
      }, {
        "featureType": "landscape",
        "elementType": "geometry",
        "stylers": [{"color": "#000000"}, {"lightness": 20}]
      }, {
        "featureType": "poi",
        "elementType": "geometry",
        "stylers": [{"color": "#000000"}, {"lightness": 21}]
      }, {
        "featureType": "road.highway",
        "elementType": "geometry.fill",
        "stylers": [{"visibility": "simplified"}, {"weight": "0.05"}, {"color": "#ffffff"}, {"lightness": 17}]
      }, {
        "featureType": "road.highway",
        "elementType": "geometry.stroke",
        "stylers": [{"color": "#5eab0f"}, {"weight": "0.10"}, {"invert_lightness": true}, {"lightness": 29}]
      }, {
        "featureType": "road.highway",
        "elementType": "labels.text.fill",
        "stylers": [{"color": "#5eab0f"}]
      }, {
        "featureType": "road.highway.controlled_access",
        "elementType": "geometry",
        "stylers": [{"weight": "0.30"}]
      }, {
        "featureType": "road.arterial",
        "elementType": "geometry",
        "stylers": [{"color": "#000000"}, {"lightness": 18}]
      }, {
        "featureType": "road.local",
        "elementType": "geometry",
        "stylers": [{"color": "#000000"}, {"lightness": 16}]
      }, {
        "featureType": "transit",
        "elementType": "geometry",
        "stylers": [{"color": "#000000"}, {"lightness": 19}]
      }, {"featureType": "water", "elementType": "geometry", "stylers": [{"color": "#000000"}, {"lightness": 17}]}]
    };

    // Get the HTML DOM element that will contain your map
    // We are using a div with id="map" seen below in the <body>
    var mapElement = document.getElementById('map-canvas');

    // Create the Google Map using our element and options defined above
    var map = new google.maps.Map(mapElement, mapOptions);

    // Let's also add a marker while we're at it
    var image = 'images/map_marker.png';
    var marker = new google.maps.Marker({
      position: new google.maps.LatLng(41.110809, 16.874760),
      map: map,
      icon: image,
      title: 'TurnOver Gaming Pub'
    });
  }

  // FORM SCRIPTS
  /*
  $("#contactForm").validator().on("submit", function (event) {
    if (event.isDefaultPrevented()) {
      // handle the invalid form...
      formError();
      submitMSG(false, "Did you fill in the form properly?");
    } else {
      // everything looks good!
      event.preventDefault();
      submitForm();
    }
  });
  */

  function submitForm() {
    // Initiate Variables With Form Content
    var name = $("#name").val();
    var email = $("#email").val();
    var message = $("#message").val();


    $.ajax({
      type: "POST",
      url: "php/form-process.php",
      data: "name=" + name + "&email=" + email + "&message=" + message,
      success: function (text) {
        if (text === "success") {
          formSuccess();
        } else {
          formError();
          submitMSG(false, text);
        }
      }
    });
  }

  function formSuccess() {
    $("#contactForm")[0].reset();
    submitMSG(true, "Message Submitted!")
  }

  function formError() {
    $("#contactForm").one('webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend', function () {
      $(this).removeClass();
    });
  }

  function submitMSG(valid, msg) {
    if (valid) {
      var msgClasses = "h3 text-center text-success";
    } else {
      var msgClasses = "h3 text-center text-danger";
    }
    $("#msgSubmit").removeClass().addClass(msgClasses).text(msg);
  }

  // NEWSLETTER SIGNUP SCRIPTS
  /*
  $("#newsletter").validator().on("submit", function (event) {
    if (event.isDefaultPrevented()) {
      // handle the invalid form...
      signupError();
      signupMSG(false, "Did you fill in the form properly?");
    } else {
      // everything looks good!
      event.preventDefault();
      submitSignup();
    }
  });
  */

  function submitSignup() {
    // Initiate Variables With Form Content
    var emailsign = $("#emailsign").val();


    $.ajax({
      type: "POST",
      url: "php/newsletter-process.php",
      data: "&emailsign=" + emailsign,
      success: function (text) {
        if (text === "success") {
          signupSuccess();
        } else {
          signupError();
          signupMSG(false, text);
        }
      }
    });
  }

  function signupSuccess() {
    $("#newsletter")[0].reset();
    signupMSG(true, "Awesome! Thank you for subscribing!")
  }

  function signupError() {
    $("#newsletter").one('webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend', function () {
      $(this).removeClass();
    });
  }

  function signupMSG(valid, msg) {
    if (valid) {
      var msgClasses = "h3 text-center tada animated text-success";
    } else {
      var msgClasses = "h3 text-center text-danger";
    }
    $("#msgSignup").removeClass().addClass(msgClasses).text(msg);
  }

});
window.onload = function () {
  /*
  //INITIALIZE ISOTIPE
  // cache container
  var $container = $('.games-portfolio');
  // initialize isotope
  $container.isotope({
    // options...
  });
  // filter items when filter link is clicked
  $('.game-tags li a').on('click', function () {
    var selector = $(this).attr('data-filter');
    $container.isotope({filter: selector});
    return false;
  });
  */

  // HIDE LOADING SCREEN WHEN PAGE IS LOADED
  $('#progress').animate({width: '100%'}, 300, function () {
    $('#loader-wrapper').addClass('loaded');
  });

}