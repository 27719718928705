/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

import BootstrapVue from 'bootstrap-vue'
import fontawesome from '@fortawesome/fontawesome'
import FontAwesomeIcon from '@fortawesome/vue-fontawesome';
import fa from '@fortawesome/fontawesome-free-regular';
import fas from '@fortawesome/fontawesome-free-solid';
import fab from '@fortawesome/fontawesome-free-brands';
import Vuelidate from 'vuelidate'
import VueScrollactive from 'vue-scrollactive';
import VueRouter from 'vue-router'
import {ZiggyVue} from 'ziggy';
import {Ziggy} from './ziggy';

import './bootstrap';
import './strider';

import 'lightbox2/dist/js/lightbox.min';
import 'lightbox2/dist/css/lightbox.min.css';

import App from './components/App';
import router from './vue-router';

window.Vue = require('vue');

Vue.use(Vuelidate);
Vue.use(VueScrollactive);
Vue.use(BootstrapVue);
Vue.use(VueRouter);
Vue.use(ZiggyVue, Ziggy);

fontawesome.library.add(fa, fas, fab);

// const files = require.context('./', true, /\.vue$/i)

// files.keys().map(key => {
//     return Vue.component(_.last(key.split('/')).split('.')[0], files(key))
// })

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

const app = new Vue({
  el: '#app',
  components: {
    App,
    FontAwesomeIcon
  },
  router
});
