import VueRouter from 'vue-router'
import HomeView from "./components/HomeView";
import MenuView from "./components/MenuView";

const router = new VueRouter({
    mode: 'history',
    routes: [
        {
            path: '/',
            name: 'home',
            component: HomeView
        },
        {
            path: '/menu',
            name: 'menu',
            component: MenuView
        },
    ],
});

export default router;
